.templateList .el-form-item-val {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  padding-left: 20px;
  min-height: 250px;
  overflow-y: auto;
}
.templateList .el-form-item-val .el-checkbox-group {
  display: flex;
  flex-direction: column;
}
.templateList .customBtn:nth-child(2) {
  background-color: #5c6be8;
  border: none;
}
.templateList .customBtn:nth-child(2):hover {
  background-color: #6875ee;
}
.templateList /deep/ .blue-background-class {
  background: rgba(92, 107, 232, 0.3);
}
